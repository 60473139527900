import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import PropTypes from 'prop-types';
import { CommonContext } from '../../context';
import ContactSuccessMessage from '../../components/ContactSuccessMessage';

export const query = graphql`
  query {
    allMessages {
      nodes {
        contact {
          success
        }
      }
    }
  }
`;
export default function ContactSuccessPage({ data, location }) {
  const message = data.allMessages.nodes[0].contact.success;
  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout title="Contact Success" location={location}>
        <ContactSuccessMessage message={message} />
      </Layout>
    </CommonContext.Provider>
  );
}

ContactSuccessPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};
